import config from '../../Config'
import * as actionTypes from '../Actions/ActionTypes'

const initialState = {
  visibility: false,
  shareUrl: null,
  title: null,
  newsId: null,
}

export const setShareLink = (state, action) => {
  if (config.IS_DEV) {
    console.log('setShareLink data: ', action.payload)
  }

  const { visibility, shareUrl, title, newsId } = action.payload.data
  return {
    ...state,
    visibility,
    shareUrl,
    title,
    newsId,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHARE_LINK:
      return setShareLink(state, action)

    default:
      return state
  }
}
