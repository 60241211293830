import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { NewsCard, Loader, Topics } from '../Components'
import { X } from 'react-bootstrap-icons'
import { debounce } from 'lodash'
import { newsService } from '../Services/NewsService'
import { validation } from '../Utils/Validation'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa6'
import { NN_TABS } from '../Contstants'
import { logEvent } from '@firebase/analytics'
import analytics from '../Utils/FirebaseAnalytics'

export default function Search() {
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState('')
  const [news, setNews] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [noResultsMessage, setNoResultsMessage] = useState(false)

  const topicsList = useSelector((state) => state.news?.topics)
  const portals = useSelector((state) => state.portals.list)

  const searchInputRef = useRef(null)

  const topics = useMemo(() => {
    const filteredTopics = topicsList?.filter((t) => t.is_category === false)
    return filteredTopics ?? []
  }, [topicsList])

  useEffect(() => {
    searchInputRef?.current?.focus()
  }, [])

  const handleSearchInput = (value) => {
    setNoResultsMessage(false)
    setSearchValue(value)

    if (value?.length >= 3) {
      debouncedSearch(value)
    } else {
      setNews([])
    }
  }

  const debouncedSearch = useCallback(
    debounce((searchInput) => searchNews(searchInput), 500),
    []
  )

  const searchNews = async (searchValue) => {
    setIsLoading(true)

    if (validation.hasValue(searchValue)) {
      const newsResponse = await newsService.getNews({ search: searchValue, perPage: 30 })
      if (newsResponse?.isOk && newsResponse.data) {
        setNews(newsResponse.data.items)

        if (newsResponse.data.items.length === 0) {
          setNoResultsMessage(true)
        }
      }
      logEvent(analytics, 'w_search_news')
    }

    setIsLoading(false)
  }

  const clearSearchInput = () => {
    setSearchValue('')
    setNews([])
    setNoResultsMessage(false)
  }

  return (
    <div className="content">
      <div className="search-page">
        <div className="search-input-div">
          <Form.Control
            ref={searchInputRef}
            onChange={(e) => handleSearchInput(e.target.value)}
            value={searchValue}
            className="search-input"
            type="text"
            placeholder="Pretraži"
          />
          <X className="clear-search-input-icon" onClick={() => clearSearchInput()} />
        </div>

        {isLoading ? (
          <Loader />
        ) : news.length > 0 ? (
          <div className="mx-2">
            {news.map((n, index) => {
              return (
                <NewsCard
                  key={index}
                  news={n}
                  isLogoVisible={true}
                  category={NN_TABS.najcitanije.value}
                />
              )
            })}
          </div>
        ) : null}

        {noResultsMessage ? (
          <div className="no-search-result">
            <p>{`Nema novosti za ovu pretragu`}</p>
          </div>
        ) : null}

        {!isLoading && news.length === 0 && !noResultsMessage ? (
          <>
            <div className="search-topics mt-4 mx-2">
              <h2>{`Aktuelne Teme`}</h2>
              <div className="grid">
                {topics
                  ?.filter((t) => t.is_category === false)
                  .map((topic, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          navigate(`/tema/${topic.name}`)
                          logEvent(analytics, 'w_topic_opened', { item: topic.name })
                        }}
                        className="py-2 px-2 pointer topics-item"
                      >
                        <div className="d-flex align-items-center justify-content-between ps-2 pe-1">
                          <div>
                            <span className="fs-6">{topic.name}</span>
                            <div className="num-of-topics">{`${
                              topic.news_count ?? 0
                            } novosti`}</div>
                          </div>
                          <FaChevronRight className="text-secondary" />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>

            <div className="search-portals mt-5 mx-2">
              <h2>{`Izvori`}</h2>
              <div className="grid">
                {portals?.map((portal, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        navigate(`/portal/${portal.title}`)
                        logEvent(analytics, 'w_portal_opened', { item: portal.title })
                      }}
                      className="py-2 px-2 pointer item"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          {/* ovdje ide slika */}
                          <img src={portal.logo} />
                          <span className="fs-6 ps-2">{portal.title}</span>
                        </div>
                        <FaChevronRight className="d-none d-sm-block text-secondary" />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        ) : null}
      </div>

      <div className="right-wrapper"></div>
    </div>
  )
}
