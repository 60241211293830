import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Loader, NewsCard, NNTabs, Topics } from '../Components'
import { IS_MOBILE, NN_TABS } from '../Contstants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { newsService } from '../Services/NewsService'
import { useSelector } from 'react-redux'
import { FaChevronLeft } from 'react-icons/fa6'

export default function TopicNews() {
  const navigate = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const cat = queryParams.get('cat')

  const [category, setCategory] = useState(cat ?? NN_TABS.najcitanije.value)
  const { topics, isHomeMounted } = useSelector((state) => state.news)

  const { topic } = useParams()

  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(false)

  const allLoaded = useRef(false)
  const page = useRef(1)

  const topicName = useMemo(() => {
    const topicFormatted = topic?.replaceAll('%20%', ' ')

    if (topicFormatted && topics?.find((t) => t.name === topicFormatted)) {
      return topicFormatted
    } else {
      return undefined
    }
  }, [topic, topics])

  const getNews = async () => {
    if (loading || allLoaded.current || !topicName) return

    setLoading(true)

    const response = await newsService.getNews({
      page: page.current,
      topic: topicName,
      category,
    })
    if (response.isOk && response.data) {
      if (response.data?.items?.length > 0) {
        const { items, current_page, pages } = response.data
        if (current_page === pages) {
          allLoaded.current = true
        }

        setNews((prevNews) => [...prevNews, ...items])
        page.current = page.current + 1
      }
    }

    setLoading(false)
  }

  const observer = useRef()
  const lastNewsElementRef = useCallback(
    (node) => {
      /* We don't want to trigger infinite scrolling if we're already loading or if news list < 10 because if the list
         has 2 news items, infinite scrolling will be triggered because last item in the list is already focused */
      if (news?.length < 10) {
        return
      }

      // Check if we have an observer and if we do - disconnect it so the last element from the new data can be hooked up correctly
      if (observer.current) {
        observer.current.disconnect()
      }

      // Set up new observer
      observer.current = new IntersectionObserver((entries) => {
        // entries[0] - since we're always observing one single node - we take the first one
        if (entries[0].isIntersecting) {
          const lastNewsElementId = entries[0]?.target?.id

          if (lastNewsElementId) {
            getNews()
          }
        }
      })

      // If something is actually our last item(node), make sure the observer is observing it
      if (node) {
        observer.current.observe(node)
      }
    },
    [news?.length, category]
  )

  useEffect(() => {
    setNews([])
    page.current = 1
    allLoaded.current = false

    setTimeout(() => {
      getNews()
    }, 400)
  }, [category, topicName])

  return (
    <div className="content">
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            if (IS_MOBILE) {
              isHomeMounted ? navigate(-1) : navigate('/najcitanije')
            } else {
              isHomeMounted ? navigate(-1) : navigate('/')
            }
          }}
          className="mt-3 mt-lg-4 ms-2 d-flex align-items-center pointer"
        >
          <FaChevronLeft className="fs-3 fw-bold" />
          <span className="fs-4 fw-bold">{topicName ?? ''}</span>
        </div>

        <NNTabs category={category} setCategory={setCategory} />

        <div className="px-2 pt-3 pt-lg-0">
          {news?.map((item, index) => {
            if (news?.length === index + 1) {
              return (
                <div id={item.id} ref={lastNewsElementRef} key={index}>
                  <NewsCard
                    news={item}
                    // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}
                    includeAd={false}
                  />
                </div>
              )
            } else {
              return (
                <NewsCard
                  key={index}
                  news={item}
                  // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}
                  includeAd={false}
                />
              )
            }
          })}
          {loading && <Loader customClass="load-more-loader" />}
        </div>
      </div>

      <div className="right-wrapper pt-5">
        <Topics />
      </div>
    </div>
  )
}
