import { newsService } from '../../Services/NewsService'
import * as actionTypes from './ActionTypes'

export const getNajcitanijeSuccess = ({ items, currentPage, allLoaded }) => {
  return {
    type: actionTypes.GET_NAJCITANIJE_SUCCESS,
    payload: { items, currentPage, allLoaded },
  }
}

export const getNajnovijeSuccess = ({ items, currentPage, allLoaded }) => {
  return {
    type: actionTypes.GET_NAJNOVIJE_SUCCESS,
    payload: { items, currentPage, allLoaded },
  }
}

export const getTopics = () => async (dispatch) => {
  const response = await newsService.getTopics()
  if (response.isOk && response.data) {
    dispatch(getTopicsSuccess(response.data))
  }
}

export const getTopicsSuccess = (topics) => {
  return {
    type: actionTypes.GET_TOPICS_SUCCESS,
    payload: { topics },
  }
}

export const setHomeMounted = () => {
  return {
    type: actionTypes.SET_HOME_MOUNTED,
  }
}
