import { useEffect, useState } from 'react'
import { Loader, NewsCard, Topics } from '../Components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { newsService } from '../Services/NewsService'
import { IoShareOutline } from 'react-icons/io5'
import { FaChevronLeft } from 'react-icons/fa6'
import { BsDot } from 'react-icons/bs'
import { fromNow, openInSameTab, openLinkInNewTab } from '../Utils/Helpers'
import { IS_MOBILE } from '../Contstants'
import { useSelector } from 'react-redux'
import config from '../Config'
import { useDispatch } from 'react-redux'
import { setShareLink } from '../Stores/Actions'
import analytics from '../Utils/FirebaseAnalytics'
import { logEvent } from '@firebase/analytics'

export default function RelatedNews() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const { id } = useParams()

  const [mainNews, setMainNews] = useState()
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(false)

  const date = mainNews ? fromNow(mainNews.pub_date) : ''

  const { isHomeMounted } = useSelector((state) => state.news)

  const shareNews = () => {
    const data = {
      visibility: true,
      shareUrl: `${config.RELATED_NEWS_LINK}${mainNews.id}?s=1`,
      title: news.title,
    }
    dispatch(setShareLink(data))
    logEvent(analytics, 'w_related_news_shared')
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const isShared = queryParams.get('s')

    if (IS_MOBILE && isShared === '1' && mainNews?.id) {
      try {
        const mobileAppDeepLink = `${config.MOBILE_URL_SCHEME}povezano/${mainNews.id}`

        window.location = mobileAppDeepLink
      } catch (error) {
        //
      }
    }
  }, [mainNews])

  useEffect(() => {
    const getNews = async () => {
      setLoading(true)

      const response = await newsService.getSimilarNews({
        id,
      })
      if (response.isOk && response.data) {
        const { news_item, similar_news } = response.data

        setMainNews(news_item)
        setNews(similar_news)
      }

      setLoading(false)
    }

    getNews()
  }, [id])

  return (
    <div className="content">
      <div>
        <div className="mt-3 mt-lg-4 ms-2 d-flex align-items-center justify-content-between">
          <div
            className="pointer"
            onClick={(e) => {
              e.stopPropagation()
              if (IS_MOBILE) {
                isHomeMounted ? navigate(-1) : navigate('/najcitanije')
              } else {
                isHomeMounted ? navigate(-1) : navigate('/')
              }
            }}
          >
            <FaChevronLeft className="fs-4 fw-bold" />
          </div>

          <div
            className="pointer"
            onClick={(e) => {
              e.stopPropagation()
              shareNews()
            }}
          >
            <IoShareOutline className="fs-4 fw-bold me-3" />
          </div>
        </div>

        <div
          className="pointer"
          onClick={() => {
            if (mainNews) {
              if (IS_MOBILE) {
                mainNews.portal === 'Hayat.ba' || mainNews.portal === 'Radio Sarajevo'
                  ? openInSameTab(mainNews.link)
                  : navigate(`/m-news?link=${mainNews.link}`)
              } else {
                openLinkInNewTab(mainNews.link)
              }
            }
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
              navigate(`/portal/${mainNews.portal}`)
            }}
            className="ms-2 mt-2 mt-lg-4"
          >
            <span className="text-uppercase text-secondary fw-bold">
              {mainNews?.portal?.toUpperCase()}
            </span>
            <BsDot />
            <span className="text-secondary">{date}</span>
          </div>

          <h5 className="mx-2 fw-bold">{mainNews?.title}</h5>
        </div>

        <h6 className="mt-4 mt-lg-5 ms-2 fw-bold mb-3">Povezane priče</h6>

        <div className="px-2">
          {news?.map((item, index) => {
            return (
              <NewsCard
                key={index}
                news={item}
                // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}
                includeAd={false}
                isSimilarNews={true}
              />
            )
          })}
          {loading && <Loader customClass="load-more-loader" />}
        </div>
      </div>

      <div className="right-wrapper pt-5">
        <Topics />
      </div>
    </div>
  )
}
