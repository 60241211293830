import React from 'react'

import presLogo from '../Assets/Images/pres.png'
import { Image } from 'react-bootstrap'
import { IS_MOBILE } from '../Contstants'
import { useLocation } from 'react-router-dom'
import config from '../Config'

export default function MobileTopBarLink() {
  const location = useLocation()

  const handleOpenClick = () => {
    let mobileAppDeepLink = `${config.MOBILE_URL_SCHEME}`

    if (location.pathname?.includes('share')) {
      mobileAppDeepLink = `${config.MOBILE_URL_SCHEME}${location.pathname}`
    }

    window.location = mobileAppDeepLink

    setTimeout(() => {
      window.location = 'https://google.com'
    }, 1000)
  }

  return (
    <>
      {IS_MOBILE && (
        <div className="top-mobile-bar">
          <div className="left-content">
            <Image src={presLogo} />
            <div>
              <p>Instapres.com</p>
              <p>Otvorite u aplikaciji</p>
            </div>
          </div>
          <button onClick={handleOpenClick} className="button">{`OTVORI`}</button>
        </div>
      )}
    </>
  )
}
