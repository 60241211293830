import React, { useEffect } from 'react'

export default function GoogleAds(props) {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || []
    try {
      window.adsbygoogle.push({})
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block', minHeight: 200, maxHeight: 350, marginBottom: 20 }}
      data-ad-format="fluid"
      data-ad-layout-key="-4v+cx-w-gg+120"
      data-ad-client="ca-pub-1423089575668905"
      data-ad-slot="3695953846"
    ></ins>
  )
}
