import axios from 'axios'
import config from '../Config'

let currentAuthToken = ''

const setToken = (token) => {
  currentAuthToken = token
}

const getApiClient = (customHeaders = {}, timeout = 10000) => {
  let headers = Object.assign(
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    customHeaders
  )

  if (currentAuthToken !== '') {
    headers.Authorization = `Bearer ${currentAuthToken}`
  }

  const baseUrl = config.API_URL
  return axios.create({
    baseURL: baseUrl,
    headers,
    timeout,
  })
}

export { getApiClient, setToken }
