import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function MobileCategories({ category }) {
  const navigate = useNavigate()

  const categoriesList = useSelector((state) => state.news?.topics)

  const categories = useMemo(() => {
    const filteredCategories = categoriesList?.filter((c) => c.is_category === true)
    return filteredCategories ?? []
  }, [categoriesList])

  return (
    <div className="d-none m-categories">
      <div className="m-categories-wrapper">
        {categories?.map((c, index) => {
          return (
            <div
              onClick={() => {
                navigate(`/tema/${c.name}?cat=${category}`)
              }}
              key={index}
              className="item"
            >
              {c.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
