import moment from 'moment'
import config from '../Config'
import { getAnalytics, logEvent } from 'firebase/analytics'

const os = {
  android: 'android',
  ios: 'ios',
}

const openLinkInNewTab = (link) => {
  window.open(link, '_blank')
}

const openInSameTab = (link) => {
  window.open(link, '_self')
}

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return null
}

const openMobileApp = () => {
  window.location.href = config.MOBILE_URL_SCHEME
}

const openSharedNewsInMobileApp = (sharedNewsId = null) => {
  if (sharedNewsId) {
    window.location.href = `${config.MOBILE_URL_SCHEME}share/${sharedNewsId}`
  }
}

const logFirebaseEvent = (eventName, eventParams = null) => {
  const analytics = getAnalytics()
  logEvent(analytics, eventName, eventParams)
}

const generateId = () => {
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length

  let result = ''
  const idLength = 10 // actual length is 12 because of added seconds
  for (let i = 0; i < idLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  const date = new Date()
  const seconds = String(date.getSeconds()).padStart(2, '0')

  return `${seconds}${result}`
}

const getHoursLabel = (hours) => {
  if (hours === 1) {
    return `1 sat`
  } else if ([2, 3, 4].includes(hours)) {
    return `${hours} sata`
  } else {
    return `${hours} sati`
  }
}

const fromNow = (date) => {
  const pubDate = moment(date)
  const now = moment()

  const diffDays = now.diff(pubDate, 'days')
  const diffHours = now.diff(pubDate, 'hours') % 24
  const diffMinutes = now.diff(pubDate, 'minutes') % 60

  let formattedTime = ''

  if (diffDays >= 1) {
    formattedTime = `${diffDays} ${diffDays === 1 ? 'dan' : 'dana'}`
  } else if (diffHours >= 1) {
    formattedTime = getHoursLabel(diffHours)
  } else {
    formattedTime = `${diffMinutes > 0 ? diffMinutes : 1} min`
  }

  return formattedTime
}

export {
  openLinkInNewTab,
  openInSameTab,
  getMobileOperatingSystem,
  os,
  openMobileApp,
  openSharedNewsInMobileApp,
  logFirebaseEvent,
  generateId,
  fromNow,
}
