import { useNavigate } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa6'
import { IS_MOBILE } from '../Contstants'
import { useSelector } from 'react-redux'

export default function TermsOfUse() {
  const navigate = useNavigate()
  const { isHomeMounted } = useSelector((state) => state.news)

  return (
    <div className="content p-4">
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            if (IS_MOBILE) {
              isHomeMounted ? navigate(-1) : navigate('/najcitanije')
            } else {
              isHomeMounted ? navigate(-1) : navigate('/')
            }
          }}
          className="mt-3 mt-lg-4 ms-2 d-flex align-items-center pointer"
        >
          <FaChevronLeft className="fs-3 fw-bold" />
          <span className="fs-4 fw-bold">Uslovi korištenja</span>
        </div>

        <div className="p-3">
          <p className="mt-1">
            Korištenjem bilo kojeg dijela Instapres platforme, saglasni ste i prihvatate sve
            navedene uslove korištenja.
          </p>

          <p>
            Ni jedan dio Instapres platforme ne smije se koristiti u nezakonite svrhe, niti za
            promovisanje istih. Instapres pruža svoje usluge korisnicima u dobroj namjeri. Sve
            usluge platforme Instapres koristite na vlastitu odgovornost i Instapres se ne može
            smatrati odgovornim za bilo kakvu štetu nastalu korištenjem. Svi posjetitelji imaju
            pravo besplatno koristiti usluge platforme Instapres, ukoliko ne krše navedene uslove
            korištenja.
          </p>

          <h5 className="fw-bold mt-4">Servis pretrage i agregacije novosti</h5>

          <p>
            Instapres pruža svojim korisnicima servis agregacije i pretrage novosti u dobroj
            namjeri. Instapres prikazuje linkove ka vijestima i sadržajima sa drugih portala uz
            jasno navođenje izvora. Platforma Instapres ne polaže autorska prava na sadržaje koji su
            linkovani i nije odgovorna za njihovu tačnost i sadržaj, iako su sažeci vijesti dostupni
            na platformi Instapres. Instapres nije odgovoran za sadržaj drugih portala, čak i ako
            ste do nekog portala došli putem linka sa platforme Instapres. Ukoliko smatrate da je
            platforma Instapres povrijedila vaša autorska prava objavom linka ili sažetka vašeg
            sadržaja, molimo vas da nas kontaktirate putem email-a:{' '}
            <a
              style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
              href="mailto:info@instapres.com"
            >
              info@instapres.com
            </a>
            .
          </p>

          <h5 className="fw-bold mt-4">Privatnost</h5>
          <p>
            Detaljno se informišite o načinu na koji platforma Instapres obrađuje vaše podatke na
            našoj stranici posvećenoj privatnosti korisnik na{' '}
            <a
              style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
              href="https://instapres.com/privacy-policy.html"
            >
              https://instapres.com/privacy-policy.html
            </a>
          </p>

          <h5 className="fw-bold mt-4">Promjene uslova korištenja</h5>
          <p>
            Instapres zadržava pravo da bez prethodne najave izmijeni ili dopuni uslove korištenja.
            Važeći uslovi korištenja uvijek su dostupni na adresi{' '}
            <a
              style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold' }}
              href="https://instapres.com/terms-of-use"
            >
              https://instapres.com/terms-of-use
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}
