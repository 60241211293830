import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NewsCard, Loader, MobileTopBarLink, NNTabs, Topics } from '../Components'
import { getMobileOperatingSystem } from '../Utils/Helpers'
import { os } from '../Utils/Helpers'
import { newsService } from '../Services/NewsService'
import { getNajcitanijeSuccess, getNajnovijeSuccess, setHomeMounted } from '../Stores/Actions/News'
import { NN_TABS } from '../Contstants'

const system = getMobileOperatingSystem()

export default function Home() {
  const dispatch = useDispatch()

  const [category, setCategory] = useState(NN_TABS.najcitanije.value)

  const [homeIsLoading, setHomeIsLoading] = useState(false)

  const najcitanijeData = useSelector((state) => state.news?.najcitanije)
  const najnovijeData = useSelector((state) => state.news?.najnovije)
  const a = useSelector((state) => {
    // console.log('state: ', state)
  })

  const news = useMemo(() => {
    if (category === NN_TABS.najcitanije.value) {
      return najcitanijeData
    } else if (category === NN_TABS.najnovije.value) {
      return najnovijeData
    } else {
      return []
    }
  }, [najcitanijeData, najnovijeData, category])

  const getNews = async ({ isLoadMore = false }) => {
    if (news?.allLoaded) {
      return
    }

    setHomeIsLoading(true)

    const response = await newsService.getNews({ page: news?.currentPage, category })
    if (response.isOk && response.data) {
      const { items, current_page, pages } = response.data
      const currentPage = current_page + 1
      const allLoaded = current_page === pages

      const newsItems = isLoadMore ? [...news.items, ...items] : items

      if (category === 'najcitanije') {
        dispatch(getNajcitanijeSuccess({ items: newsItems, currentPage, pages, allLoaded }))
      } else if (category === 'najnovije') {
        dispatch(getNajnovijeSuccess({ items: newsItems, currentPage, pages, allLoaded }))
      }
    }

    setHomeIsLoading(false)
  }

  const observer = useRef()
  const lastNewsElementRef = useCallback(
    (node) => {
      /* We don't want to trigger infinite scrolling if we're already loading or if news list < 10 because if the list
         has 2 news items, infinite scrolling will be triggered because last item in the list is already focused */
      if (news?.items?.length < 10) {
        return
      }

      // Check if we have an observer and if we do - disconnect it so the last element from the new data can be hooked up correctly
      if (observer.current) {
        observer.current.disconnect()
      }

      // Set up new observer
      observer.current = new IntersectionObserver((entries) => {
        // entries[0] - since we're always observing one single node - we take the first one
        if (entries[0].isIntersecting) {
          const lastNewsElementId = entries[0]?.target?.id

          if (lastNewsElementId) {
            getNews({ isLoadMore: true })
          }
        }
      })

      // If something is actually our last item(node), make sure the observer is observing it
      if (node) {
        observer.current.observe(node)
      }
    },
    [news?.items?.length, category]
  )

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })

    if (news?.items?.length === 0) {
      getNews({})
    }
  }, [category])

  useEffect(() => {
    dispatch(setHomeMounted())
  }, [])

  return (
    <>
      <MobileTopBarLink />
      <div
        className="content"
        style={{ marginTop: system === os.ios || system === os.android ? 60 : 0 }}
      >
        <div>
          <NNTabs category={category} setCategory={setCategory} />

          {homeIsLoading && news?.items?.length === 0 ? (
            <Loader />
          ) : (
            <div className="px-2">
              {news?.items?.map((item, index) => {
                if (news?.items?.length === index + 1) {
                  return (
                    <div id={item.id} ref={lastNewsElementRef} key={index}>
                      <NewsCard
                        news={item}
                        // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}
                        includeAd={false}
                      />
                    </div>
                  )
                } else {
                  return (
                    <NewsCard
                      key={index}
                      news={item}
                      // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}
                      includeAd={false}
                    />
                  )
                }
              })}
            </div>
          )}
        </div>
        <div className="right-wrapper pt-5">
          <Topics />
        </div>
      </div>
    </>
  )
}
