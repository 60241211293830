import config from '../Config'
import { getApiClient } from './ApiService'

const getPortals = async () => {
  const url = `/portals`

  try {
    const response = await getApiClient().get(url)
    if (response.status === 200 && response.data) {
      return {
        isOk: true,
        data: response.data,
      }
    } else {
      return {
        isOk: false,
        message: 'An error occured.',
      }
    }
  } catch (error) {
    if (config.IS_DEV) console.log('getPortals ERROR: ', error.response)
    return {
      isOk: false,
      message: error?.message,
    }
  }
}

export const portalsService = {
  getPortals,
}
