import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  openMobileApp,
  openSharedNewsInMobileApp,
  getMobileOperatingSystem,
  os,
} from '../Utils/Helpers'
import config from '../Config'

export default function AppLink(props) {
  useEffect(() => {
    try {
      const sharedNewsId = props.match.params?.id
      if (sharedNewsId) {
        openSharedNewsInMobileApp(sharedNewsId)
      } else {
        openMobileApp()
      }
    } catch (error) {}
  }, [])

  const deviceOS = getMobileOperatingSystem()
  let storeLink = ''

  if (deviceOS === os.android) {
    storeLink = config.PLAY_STORE_LINK
  } else if (deviceOS === os.ios) {
    storeLink = config.APP_STORE_LINK
  } else {
    return null // device is not android or ios
  }

  return (
    <div className="content">
      <div className="app-link-content">
        <h2>Pres.ba</h2>
        <a href={storeLink}>Instaliraj aplikaciju</a>
      </div>

      <div className="right-wrapper"></div>
    </div>
  )
}
