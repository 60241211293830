import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loader(props) {
  return (
    <div className={`spinner-div ${props.customClass}`}>
      <Spinner animation="border" variant="secondary" />
    </div>
  )
}
