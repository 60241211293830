import * as actionTypes from '../Actions/ActionTypes'

const initialState = {
  list: [],
}

const getPortalsSuccess = (state, action) => {
  return {
    ...state,
    list: action.payload.portals,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PORTALS_SUCCESS:
      return getPortalsSuccess(state, action)

    default:
      return state
  }
}
