import React from 'react'
import { FaChevronLeft } from 'react-icons/fa6'
import { useLocation, useNavigate } from 'react-router-dom'

export default function NewsIFrame() {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const newsLink = query.get('link')

  const navigate = useNavigate()

  return (
    <div className="iFrame-wrapper">
      <iframe
        src={newsLink}
        title="Instapres.com"
        height={window.screen.height}
        width={window.screen.width}
        style={{ paddingTop: '40px' }}
      ></iframe>
      <div className="header">
        <FaChevronLeft className="fs-3 fw-bold mx-2 my-1" onClick={() => navigate(-1)} />
      </div>
    </div>
  )
}
