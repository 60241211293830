import { portalsService } from '../../Services/PortalsService'
import * as actionTypes from './ActionTypes'

export const getPortals = () => async (dispatch) => {
  const response = await portalsService.getPortals()
  if (response.isOk && response.data) {
    dispatch(getPortalsSuccess(response.data))
  }
}

export const getPortalsSuccess = (portals) => {
  return {
    type: actionTypes.GET_PORTALS_SUCCESS,
    payload: { portals },
  }
}
