import { getApiClient } from './ApiService'
import config from '../Config'

const getNews = async ({
  page = 1,
  perPage = 20,
  category = undefined,
  portal = undefined,
  topic = undefined,
  search = undefined,
}) => {
  let url = `/news?page=${page}&per_page=${perPage}`

  if (category) {
    url = `${url}&category=${category}`
  }

  if (portal) {
    url = `${url}&portal=${portal}`
  }

  if (topic) {
    url = `${url}&topic=${topic}`
  }

  if (search) {
    url = `${url}&search=${search}`
  }

  if (config.IS_DEV) console.log('url: ', url)

  try {
    const response = await getApiClient().get(url)
    if (response.status === 200 && response.data) {
      return {
        isOk: true,
        data: response.data,
      }
    } else {
      return {
        isOk: false,
        message: 'An error occured.',
      }
    }
  } catch (error) {
    if (config.IS_DEV) console.log('NEWS SERVICE ERROR: ', error.response)
    return {
      isOk: false,
      message: error?.message,
    }
  }
}

const getTopics = async () => {
  const url = `/topics`

  try {
    const response = await getApiClient().get(url)
    if (response.status === 200 && response.data) {
      return {
        isOk: true,
        data: response.data,
      }
    } else {
      return {
        isOk: false,
        message: 'An error occured.',
      }
    }
  } catch (error) {
    if (config.IS_DEV) console.log('getTopics ERROR: ', error.response)
    return {
      isOk: false,
      message: error?.message,
    }
  }
}

async function getNewsById(id) {
  const url = `/news?id=${id}`

  try {
    const response = await getApiClient().get(url)
    if (response.status === 200) {
      return {
        isOk: true,
        data: response.data,
      }
    } else {
      return {
        isOk: false,
        message: 'An error occured.',
      }
    }
  } catch (error) {
    if (config.IS_DEV) {
      console.log('NEWS SERVICE ERROR: ', error)
    }
    return {
      isOk: false,
      message: error?.message,
    }
  }
}

const getSimilarNews = async ({ id }) => {
  const url = `/news/${id}/similar`

  try {
    const response = await getApiClient().get(url)
    if (response.status === 200 && response.data) {
      return {
        isOk: true,
        data: response.data,
      }
    } else {
      return {
        isOk: false,
        message: 'An error occured.',
      }
    }
  } catch (error) {
    if (config.IS_DEV) console.log('getSimilarNews ERROR: ', error.response)
    return {
      isOk: false,
      message: error?.message,
    }
  }
}

async function newsOpen(data) {
  try {
    const response = await getApiClient().post(`/news-open`, data)
    if (response.status === 200) {
      return {
        isOk: true,
      }
    }
  } catch (error) {
    //
  }
}

async function updateShareCount(newsId) {
  try {
    const response = await getApiClient().post(`/news/${newsId}/share`)
    if (response.status === 200) {
      return {
        isOk: true,
        data: response.data,
      }
    }
  } catch (error) {
    //
  }
}

export const newsService = {
  getNews,
  getTopics,
  getSimilarNews,
  getNewsById,
  newsOpen,
  updateShareCount,
}
