import { logEvent } from '@firebase/analytics'
import { Facebook, Instagram, TwitterX } from 'react-bootstrap-icons'
import { FaChevronRight } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import analytics from '../Utils/FirebaseAnalytics'

export default function Topics() {
  const navigate = useNavigate()

  const { topics } = useSelector((state) => state.news)

  return (
    <div className="desktop-topics-wrapper pt-1">
      <div className="desktop-topics">
        <h2>{`Aktuelne Teme`}</h2>
        <div>
          {topics
            ?.filter((t) => t.is_category === false)
            .map((topic, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(`/tema/${topic.name}`)
                    logEvent(analytics, 'w_topic_opened', { item: topic.name })
                  }}
                  className="py-1 pointer"
                >
                  <div className="d-flex align-items-center">
                    <span className="fs-6 topic-item">
                      {index + 1}
                      {`. `}
                      {topic.name}
                    </span>
                    <FaChevronRight className="text-secondary" />
                  </div>
                  <div className="num-of-topics">{`${topic.news_count ?? 0} novosti`}</div>
                </div>
              )
            })}
          <div className="footer">
            <div className="text-center">
              <a className="ms-1" href="/terms-of-use" target="_blank">
                Uslovi korištenja
              </a>
              <a className="ms-1" href="/privacy-policy.html" target="_blank">
                Politika privatnosti
              </a>
              <div>
                <a className="me-2" href="/o-nama" target="_blank">
                  O nama
                </a>
                <span>©2025 ABD Technology</span>
              </div>
            </div>
            <div className="social-container">
              <a href="https://www.facebook.com/instaprescom" target="_blank" rel="noreferrer">
                <Facebook className="fa-stack" />
              </a>
              <a href="https://www.instagram.com/instaprescom/" target="_blank" rel="noreferrer">
                <Instagram className="fa-stack" />
              </a>
              <a href="https://x.com/instaprescom" target="_blank" rel="noreferrer">
                <TwitterX className="fa-stack" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
