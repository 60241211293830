import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { BiSearchAlt2 } from 'react-icons/bi'
import GooglePlayLink from '../Assets/Images/google.png'
import ApplePlayLink from '../Assets/Images/apple.png'
import { openLinkInNewTab } from '../Utils/Helpers'
import config from '../Config'
import { useSelector } from 'react-redux'
import { GrHomeRounded } from 'react-icons/gr'
import { IS_MOBILE } from '../Contstants'
import { BsClock, BsFire, BsSearch } from 'react-icons/bs'
import { logEvent } from '@firebase/analytics'
import analytics from '../Utils/FirebaseAnalytics'

export default function NavBar() {
  const navigate = useNavigate()

  const { topics } = useSelector((state) => state.news)

  const [navbarHeight, setNavbarHeight] = useState(0)
  const navbarRef = useRef(null)

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const location = useLocation()

  const activeTopicCategory = useMemo(() => {
    const urlTopicName = location.pathname.replaceAll('%20', ' ')
    const activeTopicCategory = topics?.find((t) => urlTopicName.includes(t.name))

    return activeTopicCategory?.name
  }, [location.pathname, topics])

  const isNavbarVisible = useMemo(() => {
    let isVisible = true

    if (IS_MOBILE) {
      const url = location.pathname

      if (
        url.includes('/portal') ||
        url.includes('/povezano') ||
        url.includes('/tema') ||
        url.includes('/share')
      ) {
        isVisible = false
      }
    }

    return isVisible
  }, [location.pathname])

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight)
    }
  }, [navbarHeight])

  if (location.pathname === '/m-news' || !isNavbarVisible) {
    return null
  }

  return (
    <div className="navbar-wrapper">
      <nav className="my-navbar" ref={navbarRef}>
        <NavLink className="d-none d-lg-block" to="/">
          <div className="link-container" onClick={() => scrollToTop()}>
            <div className="link-content logo-link">
              <div className="logo">
                {`Instapres`}
                <span className="dot">{`.`}</span>
              </div>
            </div>
          </div>
        </NavLink>

        {IS_MOBILE ? (
          <>
            <NavLink
              to="/najcitanije"
              className={({ isActive }) => (isActive ? 'active-nav-link' : '')}
            >
              <div className="link-container" onClick={() => scrollToTop()}>
                <div className="link-content">
                  <BsFire className="nav-icon" />
                  <span>Najčitanije</span>
                </div>
              </div>
            </NavLink>

            <NavLink
              to="/najnovije"
              className={({ isActive }) => (isActive ? 'active-nav-link' : '')}
            >
              <div className="link-container" onClick={() => scrollToTop()}>
                <div className="link-content">
                  <BsClock className="nav-icon" />
                  <span>Najnovije</span>
                </div>
              </div>
            </NavLink>
          </>
        ) : (
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active-nav-link' : '')}>
            <div className="link-container" onClick={() => scrollToTop()}>
              <div className="link-content">
                <GrHomeRounded className="nav-icon" />
                <span>Početna</span>
              </div>
            </div>
          </NavLink>
        )}

        <NavLink to="/search" className={({ isActive }) => (isActive ? 'active-nav-link' : '')}>
          <div className="link-container">
            <div className="link-content">
              <BsSearch className="nav-icon" />
              <span>Pretraga</span>
            </div>
          </div>
        </NavLink>
      </nav>

      <div className="topic-categories" style={{ top: `${navbarHeight}px` }}>
        <div className="topic-categories-wrapper">
          {topics
            ?.filter((t) => t.is_category === true)
            .map((topic, index) => {
              return (
                <div
                  onClick={() => {
                    navigate(`/tema/${topic.name}`)
                    logEvent(analytics, 'w_category_opened')
                  }}
                  className={`pt-2 ps-1 ${activeTopicCategory === topic.name ? 'fw-bold' : ''}`}
                  key={index}
                >
                  {topic.name}
                </div>
              )
            })}
        </div>
      </div>

      <div className="desktop-app-links">
        {/* <div>
          <Image
            src={GooglePlayLink}
            className="app-link"
            onClick={() => openLinkInNewTab(config.PLAY_STORE_LINK)}
          />
        </div>
        <div>
          <Image
            src={ApplePlayLink}
            className="app-link"
            onClick={() => openLinkInNewTab(config.APP_STORE_LINK)}
          />
        </div> */}
      </div>
    </div>
  )
}
