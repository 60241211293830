import { combineReducers } from 'redux'
import newsReducer from './Reducers/News'
import portalsReducer from './Reducers/Portals'
import shareReducer from './Reducers/Share'

const rootReducer = combineReducers({
  news: newsReducer,
  portals: portalsReducer,
  share: shareReducer,
})

export default rootReducer
