import { useNavigate } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa6'
import { useSelector } from 'react-redux'
import { IS_MOBILE } from '../Contstants'

export default function AboutUs() {
  const navigate = useNavigate()

  const { isHomeMounted } = useSelector((state) => state.news)

  return (
    <div className="content p-4">
      <div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            if (IS_MOBILE) {
              isHomeMounted ? navigate(-1) : navigate('/najcitanije')
            } else {
              isHomeMounted ? navigate(-1) : navigate('/')
            }
          }}
          className="mt-3 mt-lg-4 ms-2 d-flex align-items-center pointer"
        >
          <FaChevronLeft className="fs-3 fw-bold" />
          <span className="fs-4 fw-bold">O nama</span>
        </div>

        <div className="p-3">
          <h5 className="fw-bold mt-2">Budi u toku sa svim važnim novostima</h5>
          <p>
            Instapres vam donosi ključne informacije na jednoj platformi. Brzo, lako i jasno – sve
            što vam treba, dostupno u trenutku.
          </p>

          <p className="fw-bold fs-5 mt-4">Šta nudi Instapres?</p>
          <ul>
            <li>
              <b>Novosti u realnom vremenu</b>: Najčitanije i najnovije priče dostupne u svakom
              trenutku.
            </li>
            <li className="mt-2">
              <b>Najvažnije teme dana</b>: Pratite šta je bitno i aktuelno.
            </li>
            <li className="mt-2">
              <b> Pametne notifikacije</b>: Birajte šta vas zanima i saznajte odmah kada se nešto
              dogodi.
            </li>
            <li className="mt-2">
              <b>Čist i pregledan dizajn</b>: Naslovi, sažeci i izvori – informacije bez ometanja.
            </li>
          </ul>

          <p className="fw-bold fs-5 mt-4">Zašto Instapres?</p>
          <p>
            Jer je važno biti informisan i korak ispred. Preuzmi aplikaciju i <b>budi u toku</b> sa
            svim što se dešava!
          </p>

          <p>
            Imate pitanja ili sugestije? Pišite nam na
            <a
              href="mailto:info@instapres.com"
              style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none' }}
            >
              {` info@instapres.com `}
            </a>
            – radujemo se vašem mišljenju!
          </p>

          <p className="fw-bold fs-5 mt-4">Izjava o odricanju od odgovornosti</p>
          <p>
            Instapres pruža svojim korisnicima servis agregacije i pretrage novosti u dobroj
            namjeri. Instapres prikazuje linkove ka vijestima i sadržajima sa drugih portala uz
            jasno navođenje izvora. Platforma Instapres ne polaže autorska prava na sadržaje koji su
            linkovani i nije odgovorna za njihovu tačnost i sadržaj, iako su sažeci vijesti dostupni
            na platformi Instapres. Instapres nije odgovoran za sadržaj drugih portala, čak i ako
            ste do nekog portala došli putem linka sa platforme Instapres. <br />
            <br />
            Ukoliko smatrate da je platforma Instapres povrijedila vaša autorska prava objavom linka
            ili sažetka vašeg sadržaja, molimo vas da nas kontaktirate putem email-a:{' '}
            <a
              href="mailto:info@instapres.com"
              style={{ color: 'black', fontWeight: 'bold', textDecoration: 'none' }}
            >
              {` info@instapres.com `}
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}
