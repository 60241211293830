import * as actionTypes from '../Actions/ActionTypes'

const initialState = {
  najcitanije: {
    items: [],
    currentPage: 1,
    allLoaded: false,
  },
  najnovije: {
    items: [],
    currentPage: 1,
    allLoaded: false,
  },
  topics: [],
  isHomeMounted: false,
}

const getPortalsSuccess = (state, action) => {
  return {
    ...state,
    portals: action.payload.portals,
  }
}

const getNajcitanijeSuccess = (state, action) => {
  return {
    ...state,
    najcitanije: { ...action.payload },
  }
}

const getNajnovijeSuccess = (state, action) => {
  return {
    ...state,
    najnovije: { ...action.payload },
  }
}

const getTopicsSuccess = (state, action) => {
  return {
    ...state,
    topics: action.payload.topics,
  }
}

const setHomeMounted = (state) => {
  return {
    ...state,
    isHomeMounted: true,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PORTALS_SUCCESS:
      return getPortalsSuccess(state, action)
    case actionTypes.GET_NAJCITANIJE_SUCCESS:
      return getNajcitanijeSuccess(state, action)
    case actionTypes.GET_NAJNOVIJE_SUCCESS:
      return getNajnovijeSuccess(state, action)
    case actionTypes.GET_TOPICS_SUCCESS:
      return getTopicsSuccess(state, action)
    case actionTypes.SET_HOME_MOUNTED:
      return setHomeMounted(state)

    default:
      return state
  }
}
