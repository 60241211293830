import React, { useEffect, useState } from 'react'
import { Loader, NewsCard, MobileTopBarLink, Topics } from '../Components'
import { newsService } from '../Services/NewsService'
import { getMobileOperatingSystem, os } from '../Utils/Helpers'
import config from '../Config'
import { useNavigate, useParams } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa6'
import { IS_MOBILE, NN_TABS } from '../Contstants'

const system = getMobileOperatingSystem()
const isMobile = system === 'android' || system === 'ios'

export default function SharedLink() {
  const navigate = useNavigate()

  const [newsList, setNewsList] = useState([])
  const [loader, setLoader] = useState(true)

  const { id } = useParams()

  useEffect(() => {
    // return if device is not mobile
    if (!IS_MOBILE) return

    try {
      const mobileAppDeepLink = `${config.MOBILE_URL_SCHEME}share/${id}`

      window.location = mobileAppDeepLink
    } catch (error) {
      //  console.log('errro: ', error)
    }
  }, [])

  useEffect(() => {
    const newsId = id

    async function getNews() {
      let news = []
      const sharedNewsResponse = await newsService.getSimilarNews({ id: newsId })

      const category = NN_TABS.najcitanije.value // Notification news are loaded only from category 'najcitanije'
      const getNewsResponse = await newsService.getNews({ category, perPage: 25 })

      if (sharedNewsResponse.isOk && sharedNewsResponse.data) {
        const { news_item } = sharedNewsResponse.data
        news = [news_item]
      }

      if (getNewsResponse.isOk && getNewsResponse.data) {
        const { items } = getNewsResponse.data
        // prevent duplicate news
        const moreNews = items?.filter((n) => n.id != newsId)
        news = [...news, ...moreNews]
      }

      setNewsList(news)
      setLoader(false)
    }

    getNews()
  }, [id])

  return (
    <>
      <MobileTopBarLink sharedNewsId={id} />
      <div className="content" style={{ marginTop: IS_MOBILE ? 60 : 0 }}>
        <div>
          <div
            onClick={() => {
              navigate('/')
            }}
            className="mt-3 mt-lg-4 ms-2 d-flex align-items-center pointer"
          >
            <FaChevronLeft className="fs-4 fw-bold" />
          </div>

          <div className="mx-2 mx-lg-0 mt-3 mt-lg-3">
            {newsList.map((item, index) => {
              return (
                <div key={index}>
                  {index === 1 ? (
                    <h5 className="mt-4 mt-lg-5 ms-2 fw-bold mb-3">Pročitaj i ovo</h5>
                  ) : null}
                  <NewsCard
                    key={item.id}
                    news={item}
                    isMobile={isMobile}
                    // includeAd={[1, 4, 8, 12, 16].some((a) => a === index)}

                    isDescriptionVisible={index === 0}
                  />
                </div>
              )
            })}
            {loader && <Loader customClass="load-more-loader" />}
          </div>
        </div>

        <div className="right-wrapper pt-5">
          <Topics />
        </div>
      </div>
    </>
  )
}
