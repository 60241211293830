import React, { useRef, useState } from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share'
import { useDispatch, useSelector } from 'react-redux'
import { GrClose } from 'react-icons/gr'
import { setShareLink } from '../Stores/Actions'
import { newsService } from '../Services/NewsService'

export default function SocialShare() {
  const dispatch = useDispatch()

  const { shareUrl, title, newsId } = useSelector((state) => state.share)
  const iconSize = 42

  const [copySuccessMessage, setCopySuccessMessage] = useState(null)
  const linkValueRef = useRef()

  const copyLink = () => {
    navigator.clipboard.writeText(shareUrl)
    setCopySuccessMessage('Link je kopiran!')
  }

  const closeSocialShare = () => {
    const shareLink = {
      visibility: false,
      shareUrl: null,
      title: null,
      newsId: null,
    }
    dispatch(setShareLink(shareLink))
  }

  const handleShareClose = () => {
    newsService.updateShareCount(newsId)
    const shareLink = {
      visibility: false,
      shareUrl: null,
      title: null,
      newsId: newsId,
      // newsId will be removed when news share count is updated
    }
    dispatch(setShareLink(shareLink))
  }

  return (
    <div className="social-share-wrapper">
      <div className="social-share-container">
        <div className="share-header">
          <span>{`Podijeli`}</span>
          <span className="close-icon" onClick={closeSocialShare}>
            <GrClose />
          </span>
        </div>
        {/* ***** Fix Messenger
        <FacebookMessengerShareButton url={shareUrl} quote={title} className="share-button">
          <FacebookMessengerIcon size={iconSize} round />
        </FacebookMessengerShareButton> */}

        <ViberShareButton
          url={shareUrl}
          quote={title}
          className="share-button"
          onShareWindowClose={handleShareClose}
        >
          <ViberIcon size={iconSize} round />
        </ViberShareButton>

        <WhatsappShareButton
          url={shareUrl}
          quote={title}
          className="share-button"
          onShareWindowClose={handleShareClose}
        >
          <WhatsappIcon size={iconSize} round />
        </WhatsappShareButton>

        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="share-button"
          onShareWindowClose={handleShareClose}
        >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          quote={title}
          className="share-button"
          onShareWindowClose={handleShareClose}
        >
          <XIcon size={iconSize} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
          quote={title}
          className="share-button"
          onShareWindowClose={handleShareClose}
        >
          <LinkedinIcon size={iconSize} round />
        </LinkedinShareButton>

        <div className="link-wrapper">
          <input ref={linkValueRef} readOnly value={shareUrl} />
          <button className="copy-button" onClick={copyLink}>{`Kopiraj`}</button>
        </div>

        {copySuccessMessage && <p className="copy-link-success-message">{copySuccessMessage}</p>}
      </div>
    </div>
  )
}
