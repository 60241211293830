const config = {
  FAKE_API_URL: 'https://60c35783917002001739e9f9.mockapi.io',
  API_URL: 'https://instapres.com/api',
  IS_DEV: false,
  PLAY_STORE_LINK: 'https://play.google.com/store/apps/details?id=com.presmobile',
  APP_STORE_LINK: 'https://apps.apple.com/us/app/pres-ba/id1588412145',
  MOBILE_URL_SCHEME: 'presmobile://',
  SHARE_URL: 'https://instapres.com/share/', // news id is appended to this constant
  RELATED_NEWS_LINK: 'https://instapres.com/povezano/', // news id is appended to this value

  FIREBASE: {
    apiKey: 'AIzaSyD1xvKA2uQHk7ePd78rz0hRjqtUvmFpan8',
    authDomain: 'instapres-4c5d8.firebaseapp.com',
    projectId: 'instapres-4c5d8',
    storageBucket: 'instapres-4c5d8.firebasestorage.app',
    messagingSenderId: '730240503765',
    appId: '1:730240503765:web:cc4bf60d64013de2dc375c',
    measurementId: 'G-3HER4KRW8S',
  },
}

export default config
