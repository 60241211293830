import { NN_TABS } from '../Contstants'

export default function NNTabs({ category, setCategory }) {
  return (
    <div className="d-flex py-3 my-2 category-tabs">
      {Object.keys(NN_TABS).map((key) => {
        return (
          <div
            key={key}
            onClick={() => {
              if (category === NN_TABS[key].value) {
                window.scroll(0, 0)
              } else {
                setCategory(NN_TABS[key].value)
              }
            }}
            className="w-100 justify-content-center d-flex pointer"
          >
            <div
              className={`align-items-center category-item ${
                category === NN_TABS[key].value ? 'active-tab' : ''
              }`}
            >
              {NN_TABS[key].icon}
              <span className="fw-bold">{NN_TABS[key].label}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}
