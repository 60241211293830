import React, { useEffect } from 'react'
import './App.scss'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/bs'

import Home from './Pages/Home'
import Search from './Pages/Search'
import SharedNews from './Pages/SharedNews'
import AppLink from './Pages/AppLink'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import NewsIFrame from './Pages/NewsIFrame'
import PortalNews from './Pages/PortalNews'
import RelatedNews from './Pages/RelatedNews'
import TopicNews from './Pages/TopicNews'
import Najcitanije from './Pages/Najcitanije'
import Najnovije from './Pages/Najnovije'

import { NavBar, SocialShare } from './Components'
import { useDispatch } from 'react-redux'
import { getPortals } from './Stores/Actions/Portals'
import { getTopics } from './Stores/Actions'
import { IS_MOBILE } from './Contstants'
import AboutUs from './Pages/AboutUs'
import TermsOfUse from './Pages/TermsOfUse'

AOS.init({ once: true })
moment.locale('bs')

function App() {
  const dispatch = useDispatch()
  const isSocialShareVisible = useSelector((state) => state.share?.visibility)

  useEffect(() => {
    dispatch(getPortals())
    dispatch(getTopics())
  }, [])

  return (
    <>
      <Router>
        {isSocialShareVisible && <SocialShare />}

        <div className="root-container">
          <NavBar />
          <div className="content-wrapper">
            <Routes>
              {IS_MOBILE ? (
                <>
                  <Route path="/" element={<Navigate to="/najcitanije" />} />
                  <Route path="/najcitanije" element={<Najcitanije />} />
                  <Route path="/najnovije" element={<Najnovije />} />
                </>
              ) : (
                <Route path="/" element={<Home />} />
              )}

              <Route path="/portal/:name" element={<PortalNews />} />
              <Route path="/povezano/:id" element={<RelatedNews />} />
              <Route path="/tema/:topic" element={<TopicNews />} />

              <Route path="/search" element={<Search />} />
              <Route path="/privacy-policy.html" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/m-news" element={<NewsIFrame />} />
              <Route path="/share/:id" element={<SharedNews />} />
              <Route path="/app-link" element={<AppLink />} />
              <Route path="/o-nama" element={<AboutUs />} />
            </Routes>
          </div>

          {/* {mobileAppLinksCounter % 5 === 0 && appLinksVisibility && (
            <div className="mobile-app-links">
              <div>
                <p>{`Otvori Pres u...`}</p>
                <div className="link-row">
                  <div>
                    <Image src={presLogo} className="icon pres-logo" />
                    <span>{`Pres aplikaciji`}</span>
                  </div>
                  <button onClick={() => openOrInstallApp()} className="button">{`Otvori`}</button>
                </div>
                <div className="link-row">
                  <div>
                    <FcGlobe className="icon" />
                    <span>{`Pretraživaču`}</span>
                  </div>
                  <button
                    onClick={() => setAppLinksVisibility(false)}
                    className="button browser-button"
                  >{`Nastavi`}</button>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </Router>
    </>
  )
}

export default App
