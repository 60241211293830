import { BsFire, BsClock } from 'react-icons/bs'
import { IoMdTime } from 'react-icons/io'
import { getMobileOperatingSystem } from '../Utils/Helpers'

const system = getMobileOperatingSystem()
export const IS_MOBILE = system === 'android' || system === 'ios'

export const NN_TABS = {
  najcitanije: {
    value: 'najcitanije',
    label: 'Najčitanije',
    icon: <BsFire className="fs-5 mb-1" />,
  },
  najnovije: {
    value: 'najnovije',
    label: 'Najnovije',
    icon: <BsClock className="fs-5 mb-1" />,
  },
}
