import React, { useEffect, useMemo, useState } from 'react'
import { Image } from 'react-bootstrap'
import { openLinkInNewTab, openInSameTab, fromNow } from '../Utils/Helpers'
import { useSelector, useDispatch } from 'react-redux'
import { FaChevronRight } from 'react-icons/fa6'
import { IoShareOutline } from 'react-icons/io5'
import { BsDot } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import GoogleAds from './GoogleAds'
import config from '../Config'
import { setShareLink } from '../Stores/Actions'
import { IS_MOBILE } from '../Contstants'
import { logEvent } from '@firebase/analytics'
import analytics from '../Utils/FirebaseAnalytics'

export default function NewsCard(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  /* 
    news: News item that is displayed in the card
    includeAd: Show ad for specific news indexes
  */
  const { news, includeAd, isDescriptionVisible, category, isSimilarNews } = props

  const [shareCount, setShareCount] = useState(news?.share_count ?? 0)

  const [imgError, setImgError] = useState(false)

  const portalsList = useSelector((state) => state.portals.list)
  const shareData = useSelector((state) => state.share)

  const portalImage = useMemo(() => {
    const portal = portalsList?.find((p) => p.title === news.portal)
    return portal?.logo
  }, [])

  const date = fromNow(news?.pub_date)

  const handleCardClick = () => {
    logEvent(analytics, 'w_news_opened')

    const newsSource = news.portal
    const newsLink = news.link

    if (IS_MOBILE) {
      const sourcesWithBlockedIFrame = [
        'Hayat.ba',
        'Radio Sarajevo',
        'Mondo',
        'Buka',
        'Bljesak.info',
        'Al Jazeera Balkans',
      ]

      sourcesWithBlockedIFrame.includes(newsSource)
        ? openInSameTab(newsLink)
        : navigate(`/m-news?link=${newsLink}`)
    } else {
      openLinkInNewTab(newsLink)
    }
  }

  const onSimilarNewsPress = (event) => {
    event.stopPropagation()
    navigate(`/povezano/${news.id}`)
    logEvent(analytics, 'w_similar_news_opened')
  }

  const shareNews = () => {
    const data = {
      visibility: true,
      shareUrl: `${config.SHARE_URL}${news.id}`,
      title: news.title,
      newsId: news.id,
    }
    dispatch(setShareLink(data))
    logEvent(analytics, 'w_news_shared')
  }

  // Update share count if news has been shared
  useEffect(() => {
    // console.log('share data: ', shareData)
    if (shareData?.newsId == news?.id && !shareData?.visibility) {
      setShareCount(shareCount + 1)

      const data = { ...shareData, newsId: null }
      dispatch(setShareLink(data))
    }
  }, [shareData])

  const paragraph = news?.description

  return (
    <>
      {includeAd && <GoogleAds />}
      <div className="card" onClick={() => handleCardClick()}>
        <div className="cont-cont">
          <div className="card-body-cont">
            <div
              onClick={(e) => {
                e.stopPropagation()
                navigate(
                  IS_MOBILE && category
                    ? `/portal/${news.portal}?cat=${category}`
                    : `/portal/${news.portal}`
                )
              }}
              className="card-source"
            >
              <Image roundedCircle src={portalImage} />
              <span className="text-uppercase text-secondary fw-bold portal-name">
                {news?.portal}
              </span>
              <BsDot />
              <span className="text-secondary">{date}</span>
            </div>

            <div className="card-title mt-2">{news?.title}</div>
            {isDescriptionVisible ? <p className="d-sm-block">{paragraph || ''}</p> : null}
          </div>
          <img
            onError={() => setImgError(true)}
            className="card-image"
            referrerPolicy="no-referrer"
            alt=""
            src={imgError || news?.image === null ? portalImage : news?.image}
          />
        </div>

        <div className="row-justify-between mt-4">
          <div className="w-100">
            {news?.group_id && !isSimilarNews && (
              <div
                className="similar-news-toggle text-centered"
                onClick={(e) => onSimilarNewsPress(e)}
              >
                <span>{`POVEZANE PRIČE (${news?.similar_news_count})`}</span>
                <FaChevronRight className="similar-news-icon" />
              </div>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation()
              shareNews()
            }}
            className="share-news-div"
          >
            <IoShareOutline className="share-news-icon" />
            <span>{shareCount}</span>
          </div>
        </div>
      </div>
    </>
  )
}
